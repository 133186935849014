import moment from 'moment';

import { BaseApiService } from '../baseApiService';
import { DtoM, StM, SrvM, Constants } from '../../modules';

enum AdminCacheKey {
    AvailableUsers = 'ADMIN_AVAILABLE_USERS',
    ArchivedUsers = 'ADMIN_ARCHIVED_USERS',
}

export class AdminApiService extends BaseApiService {
    private static _instance: AdminApiService;
    private cache: SrvM.RequestCacheService;

    constructor(config?: any) {
        if (typeof AdminApiService._instance == "undefined") {
            super(config);
            AdminApiService._instance = this;
            this.cache = new SrvM.RequestCacheService();
        }
        return AdminApiService._instance;
    }

    public getTransactions(start: moment.Moment, end: moment.Moment, club: StM.IClubStoreState): Promise<Array<StM.ITransactionStoreState>> {
        let startFormat = start.format(Constants.DateTime.API_FORMAT);
        let endFormat = end.format(Constants.DateTime.API_FORMAT);

        return this.get(`/transactions/${startFormat}/${endFormat}`)
            .then((response: any) => {
                let res = this.mapper.getTransactionModelsFromDto(<Array<ITransactionDto>>response.data, club);
                res.forEach((item) => { if (!item.session) item.session = new StM.TransactionDetailsSessionDto() });
                return res;
            });
    }

    public getUsers(clubId: number): Promise<IUserListDto[]> {
        const cachedResults = this.cache.getItem(AdminCacheKey.AvailableUsers);
        if(cachedResults) return Promise.resolve(cachedResults as IUserListDto[]);
        return this.get(`/users/${clubId}/available`)
            .then((response: any) => {
                const users = <IUserListDto[]>response.data;
                this.cache.setItem(AdminCacheKey.AvailableUsers, users);
                return users;
            });
    }

    public getArchivedUsers(clubId: number): Promise<IUserListDto[]> {
        const cachedResults = this.cache.getItem(AdminCacheKey.ArchivedUsers);
        if(cachedResults) return Promise.resolve(cachedResults as IUserListDto[]);
        return this.get(`/users/${clubId}/archived`)
            .then((response: any) => {
                const users = <IUserListDto[]>response.data;
                this.cache.setItem(AdminCacheKey.ArchivedUsers, users);
                return users;
            });
    }

    public getUser(userId: string): Promise<StM.IUserStoreState> {
        return this.get(`/users/user/${userId}`)
            .then((response: any) => {
                return this.mapper.getUserEditableModelFromDto(<IUserEditableDto>response.data);
            });
    }

    public updateUser(user: StM.IUserStoreState, skipCoachFeeTierValidation: boolean = false): Promise<StM.IUserStoreState> {
        return this.put(`/users/profile/${skipCoachFeeTierValidation}`, this.mapper.getUserEditableDtoFromModel(user))
            .then((response: any) => {
                this.cache.removeItem(AdminCacheKey.AvailableUsers);
                return this.mapper.getUserEditableModelFromDto(<IUserEditableDto>response.data);
            }).catch((error) => {
                throw (error.response.data);
            });
    }

    public resetPassword(userId: string): Promise<StM.IUserStoreState> {
        return this.post(`/users/password/${userId}`)
            .then((response: any) => {
                return this.mapper.getUserModelFromDto(<IUserDto>response.data);
            });
    }

    public deleteUser(userId: string): Promise<any> {
        return this.delete(`/users/${userId}`)
            .then((response: any) => {
                this.cache.removeItem(AdminCacheKey.ArchivedUsers);
                return response.data;
            });
    }

    public archiveUsers(userIds: Array<string>): Promise<any> {
        return this.post('/users/archive', { ids: userIds })
            .then((response: any) => {
                this.cache.removeItem(AdminCacheKey.AvailableUsers);
                this.cache.removeItem(AdminCacheKey.ArchivedUsers);
                return response.data;
            });
    }

    public deleteUsers(userIds: Array<string>): Promise<any> {
        return this.post('/users/delete', { ids: userIds })
            .then((response: any) => {
                this.cache.removeItem(AdminCacheKey.ArchivedUsers);
                return response.data;
            });
    }

    public uploadProfileImage(userId: string, file: any): Promise<any> {
        return this.post(`/users/uploadImage/${userId}`, file, { headers: { 'Content-Type': file.type } })
            .then((response: any) => {
                return null;
            }).catch((error) => {
                throw (error.response.data);
            });
    }

    public getUserBalances(userId: string): Promise<Array<ICreditsWalletBalanceDto>> {
        return this.get(`/users/balances/${userId}`)
            .then((response: any) => {
                return response.data as Array<ICreditsWalletBalanceDto>;
            });
    }

    public getUserPackages(userId: string): Promise<Array<StM.ICreditsTransactionStoreState>> {
        return this.get(`/users/packages/${userId}`)
            .then((response: any) => {
                return this.mapper.getCreditsTransactionModelsFromDto(<Array<ICreditsTransactionDto>>response.data);
            });
    }

    public getUserHistory(userId: string, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[], count: number = 5): Promise<Array<StM.ISessionStoreState>> {
        return this.get(`/users/history/${userId}/${count}`)
            .then((response: any) => {
                return this.mapper.getSessionModelsFromDtos(<Array<ISessionDto>>response.data, club, tiers);
            });
    }

    public getUserCreditsHistory(userId: string, count: number = 5): Promise<Array<StM.ICreditsTransactionStoreState>> {
        return this.get(`/users/creditsHistory/${userId}/${count * 20}`)
            .then((response: any) => {
                return this.mapper.getCreditsTransactionModelsFromDto(<Array<ICreditsTransactionDto>>response.data);
            });
    }

    public resendUserActivationEmail(userId: string): Promise<void> {
        return this.post(`/users/user/${userId}/reactivate`);
    }

    public getPackages(): Promise<Array<IPackageDefinitionDto>> {
        return this.get('/packages')
            .then((response: any) => {
                return response.data as Array<IPackageDefinitionDto>;
            });
    }

    public savePackage(packageDefinitionData: IPackageDefinitionDto): Promise<IPackageDefinitionDto> {
        return this.post('/packages', packageDefinitionData)
            .then((response: any) => {
                return response.data as IPackageDefinitionDto;
            }).catch((error) => {
                throw (error.response.data);
            });
    }

    public assignPackage(packageId: number, userId: string, note: string): Promise<IPackageDto> {
        return this.post('/packages/assign', { userId, packageId, note})
            .then((response: any) => {
                return response.data as IPackageDto;
            }).catch((error) => {
                throw (error.response.data);
            });
    }

    public archivePackage(packageId: number): Promise<any> {
        return this.post(`/packages/archive/${packageId}`)
            .then((response: any) => {
                return response.data;
            }).catch((error) => {
                throw (error.response.data);
            });
    }

    public updatePackageOrder(packageId: number, order: number): Promise<any> {
        return this.post(`/packages/updateorder/${packageId}/${order}`)
            .then((response: any) => {
                return response.data;
            });
    }

    public getSubscriptions(onlyArchived?: boolean): Promise<Array<IRecurrentPackageOfferListDto>> {
        return this.get(`/recurrent-packages${onlyArchived ? '/archived' : '/current'}`)
            .then((response: any) => {
                return response.data as Array<IRecurrentPackageOfferListDto>;
            });
    }

    public getSubscriptionHistoryById(subscriptionId: number): Promise<Array<IRecurrentPackageOfferVersionDto>> {
        return this.get(`/recurrent-packages/${subscriptionId}/versions`)
            .then((response: any) => {
                return response.data as Array<IRecurrentPackageOfferVersionDto>;
            });
    }
    
    public getSubscriptionById(subscriptionId: number): Promise<IRecurrentPackageOfferViewDto> {
        return this.get(`/recurrent-packages/${subscriptionId}`)
            .then((response: any) => {
                return response.data as IRecurrentPackageOfferViewDto;
            });
    }

    public createSubscription(subscriptionDefinitionData: IRecurrentPackageOfferEditDto): Promise<IRecurrentPackageOfferEditDto> {
        return this.post('/recurrent-packages', subscriptionDefinitionData)
            .then((response: any) => {
                return response.data as IRecurrentPackageOfferEditDto;
            }).catch((error) => {
                throw (error.response.data);
            });
    }

    public updateSubscription(subscriptionDefinitionData: IRecurrentPackageOfferEditDto, subscriptionId: number): Promise<IRecurrentPackageOfferEditDto> {
        return this.put(`/recurrent-packages/${subscriptionId}`, subscriptionDefinitionData)
            .then((response: any) => {
                return response.data as IRecurrentPackageOfferEditDto;
            }).catch((error) => {
                throw (error.response.data);
            });
    }

    public publishSubscription(subscriptionId: number): Promise<any> {
        return this.put(`/recurrent-packages/${subscriptionId}/publish`)
            .then((response: any) => {
                return response.status
            }).catch((error) => {
                throw (error.response.status);
            });
    }

    public unPublishSubscription(subscriptionId: number): Promise<any> {
        return this.put(`/recurrent-packages/${subscriptionId}/unpublish`)
            .then((response: any) => {
                return response.status;
            }).catch((error) => {
                throw (error.response.status);
            });
    }

    public cancelSubscription(subscriptionId: number): Promise<any> {
        return this.put(`/recurrent-packages/${subscriptionId}/cancel`)
            .then((response: any) => {
                return response.status;
            }).catch((error) => {
                throw (error.response.status);
            });
    }

    public archiveSubscription(subscriptionId: number): Promise<any> {
        return this.put(`/recurrent-packages/${subscriptionId}/archive`)
            .then((response: any) => {
                return response.status;
            }).catch((error) => {
                throw (error.response.status);
            });
    }

    public sendSubscriptionNotification(subscriptionId: number, title: string, text: string): Promise<ISubscribersNotificationDto> {
        return this.post(`/recurrent-packages/${subscriptionId}/subscribers/notify`, {
            title, 
            text
        }).then((response: any) => {
                return response.status;
            }).catch((error) => {
                throw (error.response.status);
            });
    }

    public getSubscriptionActiveUsers(subscriptionId: number): Promise<Array<IRecurrentPackageSubscriberDto>> {
        return this.get(`/recurrent-packages/${subscriptionId}/subscribers`)
            .then((response: any) => {
                return response.data as Promise<Array<IRecurrentPackageSubscriberDto>>;
            });
    }

    public getSubscriptionNotificationTemplates(): Promise<Array<INotificationTemplateDto>> {
        return this.get(`/notifications/templates`)
            .then((response: any) => {
                return response.data as Promise<Array<INotificationTemplateDto>>;
            });
    }

    public assignSubscription(offerId : number, offerVersion: number, userId: string, note: string): Promise<any> {
        return this.post(`/recurrent-packages/assign`, { userId, offerId, offerVersion, note})
        .then((response: any) => {
            return response.status;
        }).catch((error) => {
            throw (error.response.status);
        });
    }

    public updateSubscriptionOrder(subscriptionId: number, order: number): Promise<any> {
        return this.post(`recurrent-packages/${subscriptionId}/order/${order}`)
            .then((response: any) => {
                return response.data;
            });
    }

    public onUserCommitmentCancellation(subscriptionId: number) {
        return this.put(`/recurrent-packages/commitments/${subscriptionId}/cancel`)
            .then((response: any) => {
                return response.status;
            });
    }

    public onUserCommitmentVoid(subscriptionId: number) {
        return this.put(`/recurrent-packages/commitments/${subscriptionId}/void`)
            .then((response: any) => {
                return response.status;
            });
    }
    
    public getAddons(): Promise<Array<IAddonDefinitionDto>> {
        return this.get('/addons')
            .then((response: any) => {
                return response.data as Array<IAddonDefinitionDto>;
            });
    }

    public saveAddon(packageDefinitionData: IAddonDefinitionDto): Promise<IAddonDefinitionDto> {
        return this.post('/addons', packageDefinitionData)
            .then((response: any) => {
                return response.data as IAddonDefinitionDto;
            }).catch((error) => {
                throw (error.response.data);
            });
    }

    public archiveAddon(packageId: number): Promise<any> {
        return this.post(`/addons/archive/${packageId}`)
            .then((response: any) => {
                return response.data;
            }).catch((error) => {
                throw (error.response.data);
            });
    }

    public updateAddonOrder(packageId: number, order: number): Promise<any> {
        return this.post(`/addons/updateorder/${packageId}/${order}`)
            .then((response: any) => {
                return response.data;
            });
    }

    public updateUserWalletBalance(userId: string, balanceId: number, credits: number, note: string = "Credited by club staff"): Promise<ICreditsWalletDto> {
        return this.post(`/packages/updatebalance/${userId}/${balanceId}`, { credits, note })
            .then((response: any) => {
                return response.data as ICreditsWalletDto;
            }).catch((error) => {
                throw (error.response.data);
            });
    }

    public updateUserWalletBalances(userId: string, balances: Array<ICreditsWalletBalanceDto>, note: string = "Credited by club staff"): Promise<ICreditsWalletDto> {
        return this.post(`/packages/updatebalances/${userId}`, { balances, note })
        .then((response: any) => {
            return response.data as ICreditsWalletDto;
        }).catch((error) => {
            throw (error.response.data);
        });
    }

    public updatePackageExpirationDate(packageId: number, date: moment.Moment): Promise<void> {
        return this.put(`/packages/${packageId}/expirationDate?value=${date.format(Constants.DateTime.API_FORMAT)}`)
            .catch((error) => {
                throw (error.response.data);
            });
    }

    public getPackageSessionTypes(): Promise<Array<IPackageSessionTypeDto>> {
        return this.get('/packages/sessiontypes')
            .then((response: any) => {
                return response.data as Array<IPackageSessionTypeDto>;
            });
    }

    public getSessions(start: moment.Moment, end: moment.Moment, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<any> {
        let startDate = start.clone();
        let endDate = end.clone();

        var startFormat = startDate.format(Constants.DateTime.API_FORMAT);
        var endFormat = endDate.format(Constants.DateTime.API_FORMAT);

        return this.get(`/sessions?dateFrom=${startFormat}&dateTo=${endFormat}`)
            .then((response: any) => {
                return this.mapper.getSessionModelsFromDtos(<Array<ISessionDto>>response.data, club, tiers);
            }).catch((error) => {
                throw (error.response.data);
            });
    }

    public getTimeZones(): Promise<Array<StM.IAliasedTimeZoneStoreState>> {
        return this.get('/timezones')
            .then((response: any) => {
                return this.mapper.getAliasedTimeZoneModelsFromDtos(<Array<IAliasedTimeZoneDto>>response.data);
            });
    }

    public createClub(model: StM.INewClubStoreState): Promise<any> {
        return this.post('/clubs', this.mapper.getNewClubDtoFromModel(model))
            .then((response: any) => {
                return response.data;
            }).catch((error) => {
                throw error.response.data;
            });
    }

    public getClub(): Promise<StM.IClubStoreState> {
        return this.get('/clubs/current')
            .then((response: any) => {
                return this.mapper.getClubModelFromDto(<IClubDto>response.data);
            });
    }

    public getClubs(): Promise<Array<StM.IClubStoreState>> {
        return this.get('/clubs/all')
            .then((response: any) => {
                return this.mapper.getClubModelsFromDto(<Array<IClubDto>>response.data);
            });
    }

    public saveClub(model: StM.IClubStoreState): Promise<StM.IClubStoreState> {
        return this.post('/clubs/save', this.mapper.getClubDtoFromModel(model))
            .then((response: any) => {
                let model = this.mapper.getClubModelFromDto(<IClubDto>response.data);
                return model;
            });
    }

    public uploadImage(key: string, file: any): Promise<any> {
        return this.post(`/clubs/${key}`, file, { headers: { 'Content-Type': file.type } })
            .then((response: any) => {
                return response.data;
            }).catch((error) => {
                throw (error.response.data);
            });
    }

    public saveClubPricing(model: StM.IClubStoreState): Promise<StM.IClubStoreState> {
        return this.post('/clubs/save/pricing', this.mapper.getClubDtoFromModel(model))
            .then((response: any) => {
                let model = this.mapper.getClubModelFromDto(<IClubDto>response.data);
                return model;
            });
    }

    public saveClubTimes(models: Array<StM.IClubTimeStoreState>, club: StM.IClubStoreState): Promise<StM.IClubStoreState> {
        return this.post(`/clubs/${club.id}/savetimes`, this.mapper.getClubTimeDtosFromModels(models))
            .then((response: any) => {
                let model = this.mapper.getClubModelFromDto(<IClubDto>response.data);
                return model;
            });
    }

    public saveCourt(model: StM.ICourtStoreState): Promise<StM.ICourtStoreState> {
        return this.post('/clubs/courts', this.mapper.getCourtDtoFromModel(model))
            .then((response: any) => {
                let model = this.mapper.getCourtModelFromDto(<ICourtDto>response.data);
                return model;
            });
    }

    public deleteCourt(model: StM.ICourtStoreState): Promise<StM.ICourtStoreState> {
        return this.delete(`/clubs/courts/${model.id}`)
            .then((response: any) => {
                return response.data;
            });
    }

    public saveCourtsOrder(models: Array<StM.ICourtStoreState>): Promise<any> {
        return this.post('/clubs/courts/order', models)
            .then((response: any) => {
                return response.data;
            });
    }

    public getCoachFeeTiers(): Promise<Array<StM.ICoachFeeTierStoreState>> {
        return this.get('/clubs/coachFeeTiers')
            .then((response: any) => {
                let models = this.mapper.getCoachFeeTierModelsFromDto(<Array<ICoachFeeTierDto>>response.data);
                return models;
            });
    }

    public saveCoachFeeTier(model: StM.ICoachFeeTierStoreState, skipValidation: boolean): Promise<StM.ICoachFeeTierStoreState> {
        return this.post(`/clubs/coachFeeTiers/${skipValidation}`, this.mapper.getCoachFeeTierDtoFromModel(model))
            .then((response: any) => {
                return this.mapper.getCoachFeeTierModelFromDto(response.data);
            }).catch((error) => {
                throw error.response.data;
            });
    }

    public deleteCoachFeeTier(model: StM.ICoachFeeTierStoreState): Promise<boolean> {
        return this.delete(`/clubs/coachFeeTiers/${model.id}`)
            .then((response: any) => {
                return response.data;
            }).catch((error) => {
                throw error.response.data;
            });
    }

    public setCoachFeeTierOrder(id: number, orderNumber: number): Promise<boolean> {
        return this.post(`/clubs/coachFeeTiers/${id}/${orderNumber}`)
            .then((response: any) => {
                return response.data;
            }).catch((error) => {
                throw error.response.data;
            });
    }

    public getMembershipLievels(): Promise<Array<StM.IMembershipLevelStoreState>> {
        return this.get('clubs/membershipLevels')
            .then((response: any) => {
                let models = <Array<StM.IMembershipLevelStoreState>>response.data;
                return models;
            });
    }

    public saveMembershipLievel(model: StM.IMembershipLevelStoreState): Promise<any> {
        return this.post('/clubs/membershipLevels', this.mapper.getMembershipLevelDtoFromModel(model))
            .then((response: any) => {
                return response.data;
            });
    }

    public removeMembershipLevel(id: number): Promise<any> {
        return this.delete(`/clubs/membershipLevels/${id}`)
            .then((response: any) => {
                return response.data;
            }).catch((error) => {
                throw error.response.data;
            });
    }

    public getClubCoaches(): Promise<Array<StM.ICoachStoreState>> {
        return this.get('/club/coaches')
            .then((response: any) => {
                return this.mapper.getCoachModelsFromDtos(<Array<ICoachDto>>response.data);
            });
    }

    public getSesion(id: number, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<StM.ISessionStoreState> {
        return this.get(`/sessions/${id}`)
            .then((response: any) => {
                let model = this.mapper.getSessionModelFromDto(<ISessionDto>response.data, club, tiers);
                return model;
            });
    }

    public createSession(session: StM.ISessionStoreState, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<StM.ISessionStoreState> {
        const dto = this.mapper.getSessionDtoFromModel(session, club);
        return this.put('/sessions', dto)
            .then((response: any) => {
                this.cache.removeItem(AdminCacheKey.AvailableUsers);
                let model = this.mapper.getSessionModelFromDto(<ISessionDto>response.data, club, tiers);
                return model;
            });
    }

    public cancelSession(model: StM.ISessionStoreState, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[], force: boolean = false): Promise<any> {
        return this.put(`/sessions/${model.id}/cancel/${force}`)
            .then((response: any) => {
                this.cache.removeItem(AdminCacheKey.AvailableUsers);
                let model = this.mapper.getSessionModelFromDto(<ISessionDto>response.data, club, tiers);
                return model;
            });
    }

    public closeSession(model: StM.ISessionStoreState, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<any> {
        return this.post(`/sessions/${model.id}/close`)
            .then((response: any) => {
                let model = this.mapper.getSessionModelFromDto(<ISessionDto>response.data, club, tiers);
                return model;
            });
    }

    public reopenSession(session: StM.ISessionStoreState, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<any> {
        return this.post(`/sessions/${session.id}/reopen`)
            .then((response: any) => {
                let model = this.mapper.getSessionModelFromDto(<ISessionDto>response.data, club, tiers);
                return model;
            });
    }

    public cancelSeries(session: StM.ISessionStoreState, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[], force: boolean = false): Promise<StM.ISessionStoreState> {
        return this.put(`/sessions/series/${session.series.id}/cancel/${force}`)
            .then((response: any) => {
                this.cache.removeItem(AdminCacheKey.AvailableUsers);
                let model = this.mapper.getSessionModelFromDto(<ISessionDto>response.data, club, tiers);
                return model;
            });
    }

    public cancelSeriesById(sessionId: number, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<StM.ISessionStoreState> {
        return this.put(`/sessions/series/${sessionId}/cancel`)
            .then((response: any) => {
                this.cache.removeItem(AdminCacheKey.AvailableUsers);
                let model = this.mapper.getSessionModelFromDto(<ISessionDto>response.data, club, tiers);
                return model;
            });
    }

    public removeVideo(model: StM.ISessionStoreState, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[], waiveFee: boolean = false): Promise<StM.ISessionStoreState> {
        return this.delete(`/sessions/${model.id}/removeVideo/${waiveFee}`)
            .then((response: any) => {
                let model = this.mapper.getSessionModelFromDto(<ISessionDto>response.data, club, tiers);
                return model;
            });
    }

    public updateSession(model: StM.ISessionStoreState, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<StM.ISessionStoreState> {
        const dto = this.mapper.getSessionDtoFromModel(model, club);
        return this.put(`/sessions/${dto.id}`, dto)
            .then((response: any) => {
                this.cache.removeItem(AdminCacheKey.AvailableUsers)
                let model = this.mapper.getSessionModelFromDto(<ISessionDto>response.data, club, tiers);
                return model;
            });
    }

    public checkoutSession(model: StM.ISessionStoreState, user: StM.IAddedUserStoreState, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<StM.ISessionStoreState> {
        return this.post(`/sessions/${model.id}/checkout/${user.id}/${user.paymentType || '1'}`)
            .then((response: any) => {
                let model = this.mapper.getSessionModelFromDto(<ISessionDto>response.data, club, tiers);
                return model;
            });
    }

    public checkinUser(model: StM.ISessionStoreState, user: StM.IPublicUserStoreState, checkIn: boolean, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<StM.ISessionStoreState> {
        return this.post(`/sessions/${model.id}/checkin/${user.id}/${checkIn}`)
            .then((response: any) => {
                let model = this.mapper.getSessionModelFromDto(<ISessionDto>response.data, club, tiers);
                return model;
            });
    }

    public payForUser(model: StM.ISessionStoreState, user: StM.IPublicUserStoreState, pay: boolean, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<StM.ISessionStoreState> {
        return this.post(`/sessions/${model.id}/pay/${user.id}/${pay}`)
            .then((response: any) => {
                let model = this.mapper.getSessionModelFromDto(<ISessionDto>response.data, club, tiers);
                return model;
            });
    }

    public dropOutUser(model: StM.ISessionStoreState, user: StM.IPublicUserStoreState, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[], force: boolean = true): Promise<StM.ISessionStoreState> {
        return this.put(`/sessions/${model.id}/dropout/${user.id}/${force}`)
            .then((response: any) => {
                let model = this.mapper.getSessionModelFromDto(<ISessionDto>response.data, club, tiers);
                return model;
            });
    }

    public inviteUsers(model: StM.ISessionStoreState, users: Array<StM.IPublicUserStoreState>, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<StM.ISessionStoreState> {
        const usersDto: IAddInvitedUsersToSessionDto = {
            users: this.mapper.getPublicUserDtosFromModels(users),
            token: ''
        };
        return this.put(`/sessions/${model.id}/invite`, usersDto).then((response: any) => {
            let model = this.mapper.getSessionModelFromDto(<ISessionDto>response.data, club, tiers);
            return model;
        });
    }

    public markAsAvailable(coach: StM.IPublicUserStoreState, start: moment.Moment, duration: moment.Duration): Promise<any> {
        return this.post(`coaches/markAsAvailable/${coach.id}/${start.format(Constants.DateTime.API_FORMAT)}/${moment.utc(duration.as('milliseconds')).format(Constants.DateTime.TIME_FORMAT)}`)
            .then((response: any) => {
                return response.data;
            });
    }

    public markAsUnavailable(coach: StM.IPublicUserStoreState, start: moment.Moment, duration: moment.Duration): Promise<any> {
        return this.post(`coaches/markAsUnavailable/${coach.id}/${start.format(Constants.DateTime.API_FORMAT)}/${moment.utc(duration.as('milliseconds')).format(Constants.DateTime.TIME_FORMAT)}`)
            .then((response: any) => {
                return response.data;
            });
    }

    public getCoachAvailableTimes(start: moment.Moment, end: moment.Moment, club: StM.IClubStoreState): Promise<Array<StM.IAvailableTimeStoreState>> {
        let startFormat = start.format(Constants.DateTime.API_FORMAT);
        let endFormat = end.format(Constants.DateTime.API_FORMAT);

        return this.get(`/availableTimes/${startFormat}/${endFormat}`)
            .then((response: any) => {
                const models = this.mapper.getAvailableTimeModelsFromDto(<Array<IAvailableTimeDto>>response.data, club);

                return models;
            });
    }

    public getRecurrentAvailableTimes(coachId: string): Promise<Array<StM.IRecurrentAvailabilityStoreState>> {
        return this.get(`/recurrentAvailableTimes/${coachId}`)
            .then((response: any) => {
                const models = this.mapper.getRecurentAvailableTimeModelsFromDto(<Array<IRecurrentAvailabilityDto>>response.data);

                return models;
            });
    }

    public deleteRecurrentAvailableTime(id: number): Promise<any> {
        return this.delete(`/recurrentAvailableTimes/${id}`)
            .then((response: any) => {
                return response.data;
            });
    }

    public updateRecurrentAvailableTimes(records: Array<StM.IRecurrentAvailabilityStoreState>): Promise<Array<StM.IRecurrentAvailabilityStoreState>> {
        const dtos = this.mapper.getRecurentAvailableDtosFromModels(records);

        return this.post('/recurrentAvailableTimes', dtos)
            .then((response: any) => {
                const models = this.mapper.getRecurentAvailableTimeModelsFromDto(<Array<IRecurrentAvailabilityDto>>response.data);
                return models;
            });
    }

    public voidTransaction(bookingId: number, reason: string): Promise<any> {
        return this.put(`/transactions/void/${bookingId}`, { reason })
            .then((response: any) => {
                return response.data;
            }).catch((error) => {
                throw error.response.data;
            });
    }

    public refundTransaction(bookingId: number, amount: number, reason: string): Promise<any> {
        return this.put(`/transactions/refund/${bookingId}`, { amount, reason })
            .then((response: any) => {
                return response.data;
            }).catch((error) => {
                throw error.response.data;
            });
    }

    public voidPackageTransaction(creditsTransactionId: number, reason: string): Promise<any> {
        return this.put(`/transactions/packages/void/${creditsTransactionId}`, { reason })
            .then((response: any) => {
                return response.data;
            }).catch((error) => {
                throw error.response.data;
            });
    }

    public refundPackageTransaction(creditsTransactionId: number, amount: number, reason: string): Promise<any> {
        return this.put(`/transactions/packages/refund/${creditsTransactionId}`, { amount, reason })
            .then((response: any) => {
                return response.data;
            }).catch((error) => {
                throw error.response.data;
            });
    }

    getNotifications(club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<Array<StM.INotificationStoreState>> {
        return this.get('/notifications')
            .then((response: any) => {
                const models = this.mapper.getNotificationModelsFromDtos(<Array<INotificationDto>>response.data, club, tiers);
                return models;
            });
    }

    broadcastNotification(model: StM.IBroadcastNotificationStoreState, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<StM.INotificationStoreState> {
        return this.post('/notifications', model)
            .then((response: { data: any }) => {
                const model = this.mapper.getNotificationModelFromDto(<INotificationDto>response.data, club, tiers);
                return model;
            }).catch((error) => {
                throw error.response.data;
            });
    }

    singleNotification(model: StM.INotificationStoreState, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<StM.INotificationStoreState> {
        const dto = this.mapper.getNotificationDtoFromModel(model);
        return this.post('/notifications/single', dto)
            .then((response: { data: INotificationDto }) => {
                const model = this.mapper.getNotificationModelFromDto(response.data, club, tiers);
                return model;
            }).catch((error) => {
                throw error.response.data;
            });
    }

    dismissNotification(model: StM.INotificationStoreState, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<StM.INotificationStoreState> {
        const dto = this.mapper.getNotificationDtoFromModel(model);
        return this.put('/notifications', dto)
            .then((response: { data: INotificationDto }) => {
                const model = this.mapper.getNotificationModelFromDto(response.data, club, tiers);
                return model;
            }).catch((error) => {
                throw error.response.data;
            });
    }

    ignoreNotification(model: StM.INotificationStoreState, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<StM.INotificationStoreState> {
        const dto = this.mapper.getNotificationDtoFromModel(model);
        return this.put('/notifications/ignore', dto)
            .then((response: { data: INotificationDto }) => {
                const model = this.mapper.getNotificationModelFromDto(response.data, club, tiers);
                return model;
            }).catch((error) => {
                throw error.response.data;
            });
    }

    markNotificationAsRead(model: StM.INotificationStoreState, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<StM.INotificationStoreState> {
        const dto = this.mapper.getNotificationDtoFromModel(model);
        return this.put('/notifications/markAsRead', dto)
            .then((response: { data: INotificationDto }) => {
                const model = this.mapper.getNotificationModelFromDto(response.data, club, tiers);
                return model;
            }).catch((error) => {
                throw error.response.data;
            });
    }

    /// kiosk stuff is placed here temporarily

    findUser(email: string): Promise<StM.INotificationStoreState> {
        const container = new DtoM.UserManagableDto();
        container.email = email;

        return this.post('/kiosk/user', container)
            .then((response: any) => {
                return response;
            }).catch((error) => {
                throw error.response.data;
            });
    }

    checkIn(request: any): Promise<StM.INotificationStoreState> {
        return this.post('/kiosk/checkIn', request)
            .then((response: any) => {
                return response;
            }).catch((error) => {
                throw error.response.data;
            });
    }

    getUserSessions(userId: string): Promise<StM.INotificationStoreState> {
        return this.get(`/kiosk/sessions/${userId}`)
            .then((response: any) => {
                return response;
            }).catch((error) => {
                throw error.response.data;
            });
    }

    getStaticPages(): Promise<Array<StM.IStaticPageStoreState>> {
        return this.get('/static')
            .then((response: any) => {
                return this.mapper.getStaticPageModelsFromDtos(<Array<IStaticPageDto>>response.data);
            }).catch((error) => {
                throw error.response.data;
            });
    }

    public saveStaticPage(page: StM.IStaticPageStoreState): Promise<StM.IStaticPageStoreState> {
        return this.post('/static', page)
            .then((response: any) => {
                return this.mapper.getStaticPageModelFromDto(<IStaticPageDto>response.data);
            });
    }

    public getGroups(club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<Array<StM.IGroupStoreState>> {
        return this.get('/groups')
            .then((response: any) => {
                return this.mapper.getGroupModelsFromDtos(<Array<IGroupDto>>response.data, club, tiers);
            });
    }

    public getArchivedGroups(club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<Array<StM.IGroupStoreState>> {
        return this.get('/groups/archived')
            .then((response: any) => {
                var groups = this.mapper.getGroupModelsFromDtos(<Array<IGroupDto>>response.data, club, tiers);
                groups.forEach((group) => { group.members.forEach((member) => { member.email = member.email.indexOf(':') != -1 ? member.email.split(":")[1] : member.email; });})
                return groups;
            });
    }

    public archiveGroups(userIds: Array<number>): Promise<any> {
        return this.post('/groups/archive', { ids: userIds })
            .then((response: any) => {
                return response.data;
            });
    }

    public deleteGroups(userIds: number[]): Promise<any> {
        return this.post('/groups/delete', { ids: userIds})
            .then((response: any) => {
                return true;
            }).catch((error) => {
                throw (error.response);
            });
    }

    public getPaymentSystems(club: StM.IClubStoreState): Promise<Array<StM.IPaymentSystemEditableStoreState>> {
        return this.get('/paymentSystems')
            .then((response: any) => {
                return this.mapper.getPaymentSystemEditableModelsFromDtos(<Array<IPaymentSystemEditableDto>>response.data, club);
            }).catch((error) => {
                throw (error.response);
            });
    }

    public updatePaymentSystem(club: StM.IClubStoreState, paymentSystem: StM.IPaymentSystemEditableStoreState): Promise<StM.IPaymentSystemEditableStoreState> {
        return this.post('/paymentSystems', paymentSystem)
            .then((response: any) => {
                this.cache.removeItem(AdminCacheKey.AvailableUsers);
                return this.mapper.getPaymentSystemEditableModelFromDto(<IPaymentSystemEditableDto>response.data, club);
            }).catch((error) => {
                throw (error.response);
            });
    }
}

import { IClubStoreState } from '../store';

export class MessageTypes {
    public static Info: string = 'info';
    public static Success: string = 'success';
    public static Warning: string = 'warning';
    public static Error: string = 'error';
}

export class Messages {
    public static emlcfm: string = 'Email Confirmation Success!';
    public static chesuc: string = 'Your purchase is confirmed!';
    public static chesucpa: string = 'There was a conflict!';
    public static ses404: string = 'Session not found';
    public static premsend: string = 'Password Recovery';
    public static revalemail: string = 'Activation email';

    public static cancelsession: string = "Session successfully cancelled";
    public static cancelsessionconfirmation: string = "Cancel Session";
    public static cancelsubscrconfirmation: string = "Cancel Subscription";
    public static dropoutsession: string = "You have successfully dropped out";
    public static dropoutconfirmation: string = "Are you sure you want to drop out?";
    public static lgsuc: string = "Request has been sent successfully";
    public static supsuc: string = "MESSAGE SENT";
    public static unchcancelsession: string = "Session cannot be cancelled";
    public static unchdropoutsession: string = "Can't drop out";
    public static balanceerror: string = "Error";
    public static balanceupdate: string = "Oustanding Balance";
    public static balanceupdated: string = "Thank you!";
    public static thanks: string = "Thank you!";
    public static genericerror: string = "Error!";
    public static deluser: string = "User has been successfully removed";
    public static delusers: string = "Users have been successfully removed";
    public static adduser: string = "User successfully created";
    public static edtuser: string = "User info has been updated";
    public static updtcredits: string = "Credits have been updated";
    public static addpackage: string = "Package has been added";
    public static addsubscription: string = "Subscription has been added";
    public static archpack: string = "Package successfully archived";
    public static addsrvs: string = "Service has been added";
    public static archsrvs: string = "Service successfully archived";
    public static refundtransaction: string = "Transactions successfully refunded";
    public static voidtransactions: string = "Transaction successfully voided";
    public static settingsapplied: string = "Settings have been successfully applied";
    public static savecft: string = "Coach fee tier successfully updated";
    public static savepckg: string = "Package successfully saved";
    public static createsubscrp: string = "Subscription successfully created";
    public static updatesubscrp: string = "Subscription successfully updated";
    public static editsubscrp: string = "Subscription successfully edited";
    public static savesrvs: string = "Service successfully saved";
    public static createntfctn: string = "Notification successfully created";
    public static underageusr: string = "Stroke!";
    public static profilecompleted: string = "You are ready to play. Book your session and hit the courts.";
    public static inviterej: string = "You have rejected this invitation. Book your session and hit the courts.";
    public static decconf: string = 'Your decision is confirmed';
    public static newclub: string = "The club has been created. Please log in again.";
    public static badauthnetcreds: string = "User authentication in Authorize.NET failed due to invalid credentials";
    public static renderingerror: string = "Error!";
    public static sendinvitation: string = 'Group sessions can be scheduled once the invitation has been accepted';
    public static crtgroupacc: string = 'Group has been successfully created';
    public static acceptgroupacc: string = 'Success!';
    public static rejectgroupacc: string = 'Your selection has been confirmed';
    public static rsndgrpmmbrinvt: string = 'Your invitation has been resent';
    public static lnkalrdusd: string = 'This link has been already used';
    public static updtgrpacc: string = 'Group info has been updated'
    public static warning: string = 'Warning!';
    public static archusr: string = 'User has been successfully archived';
    public static archusrs: string = 'Users have been successfully archived';
    public static archgroupacc: string = 'Group has been successfully archived';
    public static archgroupaccs: string = 'Groups have been successfully archived';
    public static delgroupacc: string = 'Group has been successfully removed'
    public static delgroupaccs: string = 'Groups have been successfully removed'
    public static rsntactivation: string = 'Activation e-mail has been resent';
    public static publishsubscr: string = 'Subscription successfully published';
    public static unpublishsubscr: string = 'Subscription successfully hidden';
    public static archivesubscr: string = 'Subscription successfully archived';
    public static cancelsubscrsuccess: string = 'Subscription successfully cancelled';
    public static cancelsubscrerror: string = 'Subscription cancellation failed';
    public static voidsubscrsuccess: string = 'Subscription successfully voided';
    public static voidsubscrerror: string = 'Subscription void failed';
}

export class Notes {
    public static emlcfm: string = '<p>Thank you for activating your account.</p>' +
        '<p>See you on the courts!</p>';
    public static chesuc: string = '<p>See you on the courts!</p>';
    public static chesucpa: string = '<p>Please check the booking calendar and select from available sessions.</p>';

    public static packpurchok: string = '<p>Thank you for purchasing our Squash Package.</p>' +
        '<p>You will see your updated credits balance in your profile.</p>' +
        '<p>See you on the courts!</p>';

    public static ses404: string = '<p>This session was cancelled or removed.</p>';

    public static premsend: string = '<p>We\'ve emailed instructions for resetting your password. Please check your inbox and your spam file.</p>';
    public static prs: string = '<p>Password has been successfully changed.</p>';
    public static revalemail: string = '<p>Activation email has been resent. Please check your inbox and your spam folder.</p>';

    public static cancelsession: string = "";
    public static dropoutsession: string = "";
    public static lgsuc: string = "<p>Your application to join league has been sent. The Club Administrator will be in touch with additional information.</p>";
    public static unchcancelsession: string = "<p>Users have already checked in; session cannot be cancelled.</p>";
    public static unchdropoutsession: string = "<p>You have already checked in to the session.</p>";
    public static balanceerror = "<p>You have an outstanding balance!</p><p>Please update your billing information.</p>";
    public static balanceupdate = "<p>Your outstanding balance will now be processed.</p>";
    public static balanceupdated = "<p>Your outstanding balance<br/>was successfully processed!</p>";
    public static genericerror = "<p>There were issues completing your request. Please try one more time.</p>";
    public static decconf = "<p>Thank you!</p>";
    public static renderingerror = "<p>Something went wrong. Please try one more time.</p>";
    public static acceptgroupacc = "<p>You have joined the group.</p>"

    public static getNote(key: string, club: IClubStoreState): string {
        let note: string = null;
        switch(key) {
            case 'supsuc': 
                note = `<p>Thank you for contacting ${club ? club.title : ''}. We will be in touch as soon as possible.</p>`;
            break;
            default:
                note = Notes[key] || null
            break;
        }
        return note;
    }
}

export class MessagesKey {
    public static EmailConfirmation: string = 'emlcfm';
    public static CheckoutSuccess: string = 'chesuc';
    public static CheckoutSuccessPartial: string = 'chesucpa';
    public static PackageCheckoutSuccess: string = 'packpurchok';
    public static SessionNotFound: string = 'ses404';
    public static PasswordRecoveryEmailWasSend: string = 'premsend';
    public static PasswordResetSuccess: string = 'prs';
    public static CancelSession: string = 'cancelsession';
    public static CancelConfirmation: string = 'cancelsessionconfirmation';
    public static CancelSubscriptionConfirmation: string = 'cancelsubscrconfirmation';
    public static DropOutSession: string = 'dropoutsession';
    public static DropOutConfirmation: string = 'dropoutconfirmation';
    public static ValidationEmailResending: string = 'revalemail';
    public static LeagueRequestWasSend: string = 'lgsuc';
    public static SupportWasSend: string = 'supsuc';
    public static UnableCheckedInCancelSession: string = 'unchcancelsession';
    public static UnableCheckedInDropOutSession: string = 'unchdropoutsession';
    public static OutstandingBalanceError: string = 'balanceerror';
    public static OutstandingBalanceUpdate: string = 'balanceupdate';
    public static OutstandingBalanceUpdated: string = 'balanceupdated';
    public static GenericRequestError: string = 'genericerror';
    public static DeleteUser: string = 'deluser';
    public static DeleteUsers: string = 'delusers';
    public static AddUser: string = 'adduser';
    public static EditUser: string = 'edtuser';
    public static AdjustCredits: string = 'updtcredits';
    public static AddPackage: string = 'addpackage';
    public static AddSubscription: string = 'addsubscription';
    public static ArchivePackage: string = 'archpack';
    public static AddAddon: string = 'addsrvs';
    public static ArchiveAddon: string = 'archsrvs';
    public static RefundTransaction: string = 'refundtransaction';
    public static VoidTransactions: string = 'voidtransaction';
    public static ApplySettings: string = 'settingsapplied';
    public static SaveCoachFeeTier: string = 'savecft';
    public static SavePackage: string = 'savepckg';
    public static CreateSubscription: string = 'createsubscrp';
    public static UpdateSubscription: string = 'updatesubscrp';
    public static EditSubscription: string = 'editsubscrp';
    public static SaveAddon: string = 'savesrvs';
    public static CreateNotification: string = 'createntfctn';
    public static UserIsUnderage: string = 'underageusr';
    public static ProfileCompleted: string = 'profilecompleted';
    public static IniviteReject: string = 'inviterej';
    public static GenericConfirmation: string = 'thanks';
    public static DecisionConfirmation: string = 'decconf'; 
    public static NewClub: string = 'newclub';
    public static BadAuthorizeNetCredentials = 'badauthnetcreds';
    public static RenderingError: string = 'renderingerror';
    public static SendInvitation: string = 'sendinvitation';
    public static CreateGroupAccount: string = 'crtgroupacc';
    public static AcceptGroupInvitation: string = 'acceptgroupacc';
    public static RejectGroupInvitation: string = 'rejectgroupacc';
    public static ResendGroupMemberInvitation: string = 'rsndgrpmmbrinvt';
    public static InviteLinkAlreadyUsed: string = 'lnkalrdusd';
    public static UpdateGroupAccount: string = 'updtgrpacc';
    public static GeneralWarning: string = 'warning';
    public static ArchiveUser: string = 'archusr';
    public static ArchiveUsers: string = 'archusrs';
    public static ArchiveGroupAccount: string = 'archgroupacc';
    public static ArchiveGroupAccounts: string = 'archgroupaccs';
    public static DeleteGroupAccount: string = 'delgroupacc';
    public static DeleteGroupAccounts: string = 'delgroupaccs';
    public static ResendActivationEmail: string = 'rsntactivation';
    public static CheckSpamMailMessage: string = 'checkspammsg';
    public static PublsishSubscription: string = 'publishsubscr';
    public static UnPublsishSubscription: string = 'unpublishsubscr';
    public static ArchiveSubscription: string = 'archivesubscr';
    public static CancelSubscription: string = 'cancelsubscrsuccess';
    public static CancelSubscriptionError: string = 'cancelsubscrerror';
    public static VoidSubscription: string = 'voidsubscrsuccess';
    public static VoidSubscriptionError: string = 'voidsubscrerror';
}

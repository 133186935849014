import './imageUploader.scss';

import * as React from 'react';
import DropZone from 'react-dropzone';

interface IImageUploaderProps {
    url: string;
    multiple?: boolean;
    maxSize?: number;
    accept?: string;
    imageOnly?: boolean;
    readonly?: boolean;

    onDrop: (acceptedFiles: any, rejectedFiles: any) => void;
}

export class ImageUploader extends React.Component<IImageUploaderProps> {
    private _defaultMaxSize = 524288000;
    private _defaultAccept = "image/jpeg, image/png, image/svg+xml";
    constructor(props: IImageUploaderProps) {
        super(props);
    }

    public render() {
        return (
            <div className="img-uploader-wrapper">
                <div className="img-uploader">
                    {this.props.imageOnly && this.renderImage(this.props.url)}
                    {!this.props.imageOnly && this.renderDropzone()}
                </div>
            </div>
        );
    }

    private renderDropzone() {
        return (
            <DropZone
                onDrop={(acc, rej) => this.onDrop(acc, rej)}
                multiple={this.props.multiple}
                maxSize={this.props.maxSize || this._defaultMaxSize}
                accept={this.props.accept || this._defaultAccept}
                disabled={this.props.readonly}
            >
                {({ getRootProps, getInputProps }) => (
                    <section className="img-uploader-dropzone" {...getRootProps()}>
                        <input {...getInputProps()} />
                        {this.renderImage(this.props.url)}
                        <button 
                            disabled={this.props.readonly} 
                            className="img-upload-btn"
                            type="button"
                        >
                            Upload Photo
                        </button>
                    </section>
                )}
            </DropZone>
        );
    }

    private renderImage(url: string) {
        return (
            <div className="img-uploader-photo-wrapper">
                <img src={url} className="img-uploader-photo" />
            </div>
        );
    }

    private onDrop(acceptedFiles: any, rejectedFiles: any) {
        if (!this.props.imageOnly) {
            this.props.onDrop(acceptedFiles, rejectedFiles);
        }
    }
}

export default ImageUploader;

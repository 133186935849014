
import * as Redux from 'redux';
import * as _ from 'lodash';

import * as StM from '../../models/store';
import { ThunkService } from '../../services/thunkService';
import { AppActions } from '../appActions';
import { DialogActions } from '../dialogs/index';

export class AdminUsersActions {
    private static _instance: AdminUsersActions;
    
    public static get(clubId?: number, onlyArchived?: boolean):
        (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.IUserListStoreState[] | void> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkSrv: ThunkService) => {
            dispatch(AppActions.showSpinner());
            if(!clubId) clubId = getState().club.id;
            const getUsersP = onlyArchived ? thunkSrv.api.admin.getArchivedUsers(clubId) : thunkSrv.api.admin.getUsers(clubId);
            return Promise.resolve(getUsersP)
                .then((users) => {
                    const results = this.restoreImplicitProperties(users, getState());
                    if(!onlyArchived) {
                        dispatch({ payload: results, type: 'Admin/Users' });
                        dispatch({ payload: getState().usersListVersion + 1, type: 'Admin/Users/Changed' });
                    } else {
                        dispatch({ payload: results, type: 'Admin/ArchivedUsers' });
                    }
                    dispatch(AppActions.hideSpinner());
                    return results;
                }).catch(() => {
                    dispatch(AppActions.hideSpinner());
                });
        };
    }
    
    private static restoreImplicitProperties(users: IUserListDto[], state: StM.IGlobalStoreState): StM.IUserListStoreState[] {
        return users.map((user) => {
            const result = new StM.UserListStoreState(user);
            if(user.level) result.level = state.membershipLevels.find(m => m.id === user.level.id);
            if(user.membershipLevels) result.membershipLevels = user.membershipLevels.map(m => state.membershipLevels.find(l => l.id === m.id));
            if(user.group) result.group = state.admin.groups.find(g => g.id === user.group.id);
            return result;
        });
    }

    public static updateUser(user: StM.IUserStoreState, isNew: boolean = false, skipCoachFeeTierValidation: boolean = false):
        (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.IUserStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkSrv: ThunkService) => {
            return thunkSrv.api.admin.updateUser(user, skipCoachFeeTierValidation)
                .then((result) => {
                    if(isNew) dispatch({payload: result, type: 'Admin/Users/Add'});
                    dispatch({payload: result, type: 'Admin/Users/Update'});
                    dispatch({payload: getState().usersListVersion + 1, type: 'Admin/Users/Changed'});
                    return result;
                }).catch((error) => {
                    throw error;
                });
        };
    }

    public static updateGroup(group: StM.IGroupStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunk: ThunkService) => Promise<StM.IGroupStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunk: ThunkService) => {
            return thunk.api.group.update(group, getState().club)
                .then((response) => {
                    dispatch({ type: 'Admin/Groups/Update', payload: response });
                    return response;
                }).catch((error) => {
                    throw error.data;
                });
        };
    }

    public static getUser(userId: string, shouldUpdateState: boolean = false):
        (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.IUserEditableStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkSrv: ThunkService) => {
            return thunkSrv.api.admin.getUser(userId).then((user: StM.IUserEditableStoreState) => {
                if (_.some(user.permissions, (y) => y.role.toLowerCase() == 'coachadmin')) user.permissions = user.permissions.filter((z) => z.role.toLowerCase() == 'coachadmin')
                if (shouldUpdateState) {
                    dispatch({payload: user, type: 'Admin/Users/Update'});
                    dispatch({payload: getState().usersListVersion + 1, type: 'Admin/Users/Changed'});
                }               
                return user;
            });
        };
    }

    public static deleteUser(userId: string):
        (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkSrv: ThunkService) => {
            return thunkSrv.api.admin.deleteUser(userId);
        };
    }

    public static getHistory(userId: string):
        (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<Array<StM.ISessionStoreState>> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkSrv: ThunkService) => {
            const { club, pricingTiers } =  getState();
            return thunkSrv.api.admin.getUserHistory(userId, club, pricingTiers);
        };
    }

    public static getCreditsHistory(userId: string):
    (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<Array<StM.ICreditsTransactionStoreState>> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkSrv: ThunkService) => {
            return thunkSrv.api.admin.getUserCreditsHistory(userId);
        };
    }

    public static updateProfileImage(userId: string, file: any):
        (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkSrv: ThunkService) => {
            return thunkSrv.api.admin.uploadProfileImage(userId, file);
        };
    }

    public static archiveUsers(userIds: string[]):
        (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkSrv: ThunkService) => {
            dispatch(AppActions.showSpinner());
            return thunkSrv.api.admin.archiveUsers(userIds)
                .then((response) => {
                    dispatch(AppActions.hideSpinner());
                    dispatch(AdminUsersActions.get(getState().club.id));
                    return response;
                })
                .catch((error) => {
                    dispatch(AppActions.hideSpinner());
                    throw error.data;
                });
        };
    }

    public static deleteUsers(userIds: string[]):
        (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkSrv: ThunkService) => {
            dispatch(AppActions.showSpinner());
            return thunkSrv.api.admin.deleteUsers(userIds)
                .then((response) => {
                    dispatch(AppActions.hideSpinner());
                    dispatch(AdminUsersActions.get(getState().club.id, true));
                    return response;
                })
                .catch((error) => {
                    dispatch(AppActions.hideSpinner());
                    throw error.data;
                });
        };
    }

    public static resendActivationEmail(userId: string): (dispatch: any, getState: () => StM.IGlobalStoreState, thunk: ThunkService) => Promise<void> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunk: ThunkService) => {
            dispatch(AppActions.showSpinner());
            return thunk.api.admin.resendUserActivationEmail(userId)
                .then(() => {
                    dispatch({payload: getState().usersListVersion + 1, type: 'Admin/Users/Changed'});
                    dispatch({payload: userId, type: 'Admin/Users/EnableUser'});
                    dispatch(DialogActions.close(StM.DialogNames.Confirm));
                    dispatch(DialogActions.open(StM.DialogNames.Alert, {msgKey: StM.MessagesKey.ResendActivationEmail, messageType: StM.MessageTypes.Success }));
                    dispatch(AppActions.hideSpinner());
                })
                .catch((error) => {
                    dispatch(AppActions.hideSpinner());
                    throw error.data;
                });
        }
    }

    static onUserCommitmentCancellation(id: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.onUserCommitmentCancellation(id);
        }
    }

    static onUserCommitmentVoid(id: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.onUserCommitmentVoid(id);
        }
    }
}
